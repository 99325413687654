<template>
      <b-row>
        <b-col :class="is_detail_network ? 'col-12' : 'p-1 col-12 col-md-6'" v-if="countries && countries[0] !== false && countries.length > 2">
          <b-card no-body :class="is_detail_network ? 'border-box-analytics' : ''">
            <b-card-header>
              <b-card-title>
                {{$t('instagram.mainCountries')}}
              </b-card-title>
            </b-card-header>
            <b-card-body>
              <component :is="apex_charts" type="bar" height="300" :options="countryOptions" :series="[{name: $t('youtube.countries'), data: countriesSeries}]"></component>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col class="p-1 col-12 col-md" v-if="languages && languages[0] !== false && languages.length > 0">
          <b-card no-body :class="is_detail_network ? 'border-box-analytics' : ''">
            <b-card-header>
              <b-card-title>
                {{$t('youtube.mainIdioms')}}
              </b-card-title>
            </b-card-header>
            <b-card-body>
              <component :is="apex_charts" type="bar" height="300" :options="languagesOptions" :series="[{name: $t('youtube.idioms'), data: languagesSeries}]"></component>
            </b-card-body>
          </b-card>
        </b-col>
        
        <b-col class="col-12">
          <b-card :class="is_detail_network ? 'border-box-analytics' : ''">
            <b-card-header>
              <b-card-title>{{$t('search.audienceGender')}}</b-card-title>
            </b-card-header>
            <b-card-body>
              <component :is="apex_charts" type="bar" height="350" :options="ageGenderOptions" :series="ageGenderSeries" v-if="Object.keys(age_gender).length > 0"/>
              <div v-else class="text-muted">{{$t('tiktok.no_data_sorry')}}</div>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col class="col-12">
          <b-card :class="is_detail_network ? 'border-box-analytics' : ''">
            <b-card-header>
              <b-card-title>{{$t('tiktok.audiences_races')}}</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-col class="col-12" v-if="Object.keys(audience_races).length > 0">
                <component :is="apex_charts" type="bar" height="350" :options="audienceRacesOptions" :series="audienceRacesSeries"/>
              </b-col>
              <div v-else class="text-muted">
                {{$t('tiktok.no_data_sorry')}}
              </div>
            </b-card-body>
         </b-card>
        </b-col>

        <b-col class="col-12">
          <b-card :class="is_detail_network ? 'border-box-analytics' : ''">
            <b-card-header>
              <b-card-title>
                {{$t('general.type_audience')}}
              </b-card-title>
            </b-card-header>
            <b-card-body>
              <b-row v-if="Object.keys(audience_by_type).length > 0">
                <b-col class="col-12">

                  <b-row class="d-flex align-items-center justify-content-between">
                    <b-col :class="is_detail_network ? 'col-12' : 'col-12 col-md-3'">
                      <component :is="apex_charts" height="200" :options="audienceTypeOptions" :series="audienceTypeSeries" />
                    </b-col>
                    <b-col :class="is_detail_network ? 'col-12' : 'col-12 col-md-8'">
                      <component :is="apex_charts" type="bar" height="300" :options="audienceTypeOptionsBar" :series="audienceTypeSeriesBar" />
                    </b-col>
                  </b-row>
                </b-col>

                <b-col>
                  <div v-if="audience_reachability !== null">
                    <p class="d-flex align-items-center">
                      <span :class="`dot-profile-tiktok ${getColorDotProfile(audience_reachability.mark)}`"/> 
                      <span class="h">{{$t(`tiktok.${audience_reachability.mark}`)}}</span>
                    </p>
                    <p>
                      <strong>{{audience_reachability.value}}%</strong>
                      {{$t('tiktok.audience_from')}} <strong>@{{username}}</strong> {{$t('tiktok.are_real_people')}} 
                      <strong>{{decimalFixed(audience_reachability.similar, 2)}} %</strong> {{$t('tiktok.authenticity_of')}}.
                    </p>
                  </div>
                </b-col>
              </b-row>
              <div v-else class="text-muted">
                {{$t('tiktok.no_data_sorry')}}
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
</template>
<script>
import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody } from 'bootstrap-vue'
import { getColorDotProfile } from '@/libs/utils/series_graphs';
import { decimalFixed, capitalize } from '@/libs/utils/formats';
import { getCountry , getLanguage } from '@/libs/utils/others';

export default {
  name: 'featuresOne',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },
  props: {
    username: {
      type: String,
    },
    languages: {
      type: Array,
      default: () => [false]
    },
    countries: {
      type: Array,
      default: () => [false]
    },
    age_gender: {
      type: Object, 
      default: () => {}
    },
    audience_races: {
      type: Object, 
      default: () => {}
    },
    audience_by_type: {
      type: Object,
      default: () => {}
    },
    audience_reachability: {
      type: Object,
      default: () => {}
    },
    is_detail_network: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      getLanguage,
      getCountry,
      getColorDotProfile,
      decimalFixed,
      genders: ['female', 'male'],
      apex_charts: null,
    }
  },
  async mounted() {
    this.apex_charts = (await import('vue-apexcharts')).default;
  },
  methods: {
    getCategoriesAgeGender() {
      return Object.keys(this.age_gender).sort()
    },
    getSerieAgeGender() {
      const obj = {
        male: [],
        female: []
      }
      for (const prop in this.age_gender) {
        obj.male.push(this.age_gender[prop].male)
        obj.female.push(this.age_gender[prop].female)
      }
      obj.male.splice(obj.male.length, 0, obj.male[0])
      obj.female.splice(obj.male.length, 0, obj.female[0])
      obj.male.splice(0, 1)
      obj.female.splice(0, 1)
      return obj
    },
    getSerieRaces() {
      return Object.values(this.audience_races);
    },
    getCategoriesRaces() {
      return Object.keys(this.audience_races);
    },
    getSerieAudienceType() {
      return Object.values(this.audience_by_type)
    },
  },
  computed: {
    countryOptions() {
      const options = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: '#7E4DCD',
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '15%',
            endingShape: 'rounded',
            colors: {
              backgroundBarColors: ['#E1E7F0', '#E1E7F0', '#E1E7F0']
            },
            dataLabels: {
              position: 'top',
            }
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -15,
          // offsetX: 10,
          formatter: (value) => {
            return `${value}%` 
          },
          style: {
            fontSize: '13px',
            colors: ['gray']
          }
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        yaxis: {
          max: 100,
        },
        xaxis: {
          categories: this.countries.map(item => { return getCountry(item.title ? item.title : item.id ? item.id : item.code) }),
          show: true,
          labels: {
            show: false
          }
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        }
      }
      return options
    },
    countriesSeries() {
      const serie = this.countries.map(item => { return item.prc })
      return serie
    },
    languagesOptions() {
      const cat = this.languages.map(item => {
        if (item.title !== 'Other') return getLanguage(item.title ? item.title === 'zh-Hant' ? 'zh' : item.title : item.code === 'zh-Hant' ? 'zh' : item.code)
        return item.title
      })
      const options = {
        chart: {
          stackType: '100%',
          toolbar: {
            show: false,
          },
        },
        colors: '#7E4DCD',
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '15%',
            endingShape: 'rounded',
            colors: {
              backgroundBarColors: ['#E1E7F0', '#E1E7F0', '#E1E7F0']
            },
            dataLabels: {
              position: 'top',
            }
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -15,
          offsetX: 10,
          formatter: (value) => {
            return `${value}%` 
          },
          style: {
            fontSize: '13px',
            colors: ['gray']
          }
        },
        yaxis: {
          max: 100,
        },
        xaxis: {
          categories: cat,
          show: true,
          labels: {
            show: false
          }
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return `${val}%`
            }
          }
        }
      }
      return options
    },
    languagesSeries() {
      const serie = this.languages.map(item => { return item.prc })
      return serie
    },
    ageGenderOptions() {
      const options = {
        colors: ['#EA33EF', '#2B7BD8'],
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "27%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: this.getCategoriesAgeGender()
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          custom: ({seriesIndex, dataPointIndex, w}) => {
            return (
              `<div class="width-tooltip-graph">
                  <div class="d-block bg-toottip-graph p-1">
                    ${this.$t('tiktok.audience_of')} ${this.getCategoriesAgeGender()[dataPointIndex]} ${this.$t('tiktok.years')}
                  </div>
                  <div class="p-1">
                    <strong class="mr-tooltip-graph">${this.$t(`instagram.${this.genders[seriesIndex]}`)}: 
                    </strong> ${(w.globals.initialSeries[seriesIndex].data[dataPointIndex])} %
                  </div>
                </div>`
            )
          }
        },
      }
      return options
    },
    ageGenderSeries() {
      const series = [
        {
          name: this.$t('instagram.female'),
          data: this.getSerieAgeGender().female
        },
        {
          name: this.$t('instagram.male'),
          data: this.getSerieAgeGender().male
        },
      ]
      return series
    },
    audienceRacesSeries() {
      const series = [
        {
          data: this.getSerieRaces(),
        },
      ]
      return series
    },
    audienceRacesOptions() {
      const options = {
        tooltip: {
          custom: ({ seriesIndex, dataPointIndex, w}) => {
            return (
              `<div class="width-tooltip-graph">
                  <div class="p-1">
                    <strong class="mr-tooltip-graph"> ${capitalize(this.getCategoriesRaces()[dataPointIndex])}:
                    </strong> ${w.globals.initialSeries[seriesIndex].data[dataPointIndex]} %
                  </div>
                </div>`
            )
          }
        },
        colors: ['#08C3C3'],
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
            endingShape: 'flat',
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: false,
        },

        xaxis: {
          categories: this.getCategoriesRaces(),
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
          },
        },
      }
      return options
    },
    audienceTypeOptions() {
      const options = {
        labels: [this.$t('tiktok.bots'), this.$t('tiktok.infs'), this.$t('tiktok.mass'), this.$t('tiktok.real')],
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (value) => {
              return `${value}%`
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        stroke: { width: 0 },
        plotOptions: {
          pie: {
            startAngle: 0,
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    return `${parseInt(val)}%`
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: `${this.$t('tiktok.real')}`,
                  formatter: () => { return `${this.audienceTypeSeries[this.audienceTypeSeries.length - 1]}%` },
                },
              },
            },
          },
        },
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
        responsive: [
          {
            breakpoint: 1350,
            options: {
              chart: {
                height: 150,
              },
            },
          },
        ]
      }
      return options;
    },
    audienceTypeSeries() {
      const series = this.getSerieAudienceType();
      return series;
    },
    audienceTypeSeriesBar() {
      const series = [
        {
          data: this.getSerieAudienceType()
        }
      ]
      return series;
    },
    audienceTypeOptionsBar() {
      const options = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: '#7E4DCD',
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '15%',
            endingShape: 'rounded',
            colors: {
              backgroundBarColors: ['#E1E7F0', '#E1E7F0', '#E1E7F0']
            },
            dataLabels: {
              position: 'top',
            }
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -15,
          offsetX: 10,
          formatter: ((val) => {
            return `${val}%`
          }),
          style: {
            fontSize: '13px',
            colors: ['gray']
          }
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        yaxis: {
          max: 100,
        },
        xaxis: {
          categories: [this.$t('tiktok.bots'), this.$t('tiktok.infs'), this.$t('tiktok.mass'), this.$t('tiktok.real')],
          show: true,
          labels: {
            show: false
          }
        },
        tooltip: {
          custom: ({ seriesIndex, dataPointIndex, w}) => {
            return (
              `<div class="width-tooltip-graph">
                  <div class="p-1">
                    <strong class="mr-tooltip-graph"> ${(this.audienceTypeOptionsBar.xaxis.categories[dataPointIndex])}:
                    </strong> ${w.globals.initialSeries[seriesIndex].data[dataPointIndex]} %
                  </div>
                </div>`
            )
          }
        },
      }
      return options
    },
  }
}
</script>
